import React, { useCallback, useMemo, useState } from 'react';
import { Col, LabeledSelect } from '@commonsku/styles';
import Avatar from 'react-avatar';

export default function CompanyCommissionPeopleRate({
    item, saveItem, rates
}) {
    const defaultValues = useMemo(() => ({
        client_rep_commission_rate_id: '',
        order_rep_commission_rate_id: '',
        combined_rep_commission_rate_id: '',
    }), []);

    const sourceOptions = [{ value: '', label: '' }].concat(
        rates.map(r => ({
            label: `${r.commission_rate_label} (${Number(r.commission_rate).toFixed(0)} % of ${r.commission_source.toLowerCase()})`,
            value: r.commission_rate_id,
        }))
    );

    const [form, setForm] = useState(item || defaultValues);

    const setFormFieldValue = useCallback((e) => {
        const target = e.target;
        setForm((form) => {
            const newValues = { ...form, [target.name]: target.value };
            return newValues;
        });
    }, []);

    const getSelect = (name, col = 3) => {
        let value = form[name];
        if (!value) {
            value = null;
        }
        const selectedSource = sourceOptions.find(c => c.value === value);
        return (
            <Col sm={col} padded>
                <LabeledSelect
                    name={name}
                    value={selectedSource}
                    options={sourceOptions}
                    onChange={(selected) => {
                        setFormFieldValue({ target: { name: name, value: selected.value } });
                        saveItem(form, name, selected.value);
                    }}
                />
            </Col>);
    };

    let formFields = (
        <>
            <Col padded sm={3}><Avatar src={item.user_image_paths?.small || null} name={item.contact_full_name} round={true} size={30} /> {item.contact_full_name}</Col>
            {getSelect('client_rep_commission_rate_id')}
            {getSelect('order_rep_commission_rate_id')}
            {getSelect('combined_rep_commission_rate_id')}
        </>
    );

    return formFields;
}
