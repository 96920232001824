import React from 'react';

const AdminAvalaraPaywall = () => {

  return <div style={{ maxWidth: 990 }}>
    Upgrade to Advanced to unlock Avalara integration
    Easy, fast and accurate automated tax solution to save time and money. 
  </div>;
};

export default AdminAvalaraPaywall;