import { Input, Row, Col, LabeledSelect, Loading } from '@commonsku/styles';
import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import useQuickbookCustomField from '../../hooks/useQuickbookCustomField';
import { qbo_custom_field_options } from '../../../../company_setting_options';

export default function CustomField({ qboCustomFields }) {
    const [fields, save] = useQuickbookCustomField();

    // Well, we have to keep/modify the state here
    // Since it is a weird setup in qbo custom fields mapping
    const [mapped, setMapped] = useState([]);

    useEffect(() => {
        setMapped(qboCustomFields);
    }, [qboCustomFields]);

    const getSelect = (qboField, ref) => {
        const value = qboField.mapped_field ? qbo_custom_field_options.filter(c => c.value === qboField.mapped_field)[0] : null;
        return (
            <>
                <LabeledSelect
                    isClearable
                    placeholder='Select a commonsku option'
                    label=''
                    options={qbo_custom_field_options}
                    value={value}
                    onChange={async (item) => {
                        item = item === null ? {label: '', value: ''} : item;
                        let data = {
                            qbo_custom_field_id: qboField.qbo_custom_field_id,
                            mapped_field: item.value,
                            qbo_custom_field_ref: ref,
                        };
                        const savedData = await save(data);
                        setMapped((old) => {
                            const newFields = [];
                            for (const f of old) {
                                if (f.StringValue === qboField.StringValue) {
                                    newFields.push({...f, qbo_custom_field_id: savedData.qbo_custom_field_id, mapped_field: item.value});
                                } else {
                                    newFields.push(f);
                                }
                            }

                            return newFields;
                        });
                    }}
                />
            </>
        );
    };

    const currentMappings = mapped.map((qboField, index) => {
        return (
            <Row key={'qbo-currency-' + qboField.StringValue}>
                <Col sm={6}>
                    <Input value={qboField.StringValue} disabled={true} style={{ height: '42px' }}></Input>
                </Col>
                <Col sm={6} style={{paddingLeft: '1rem'}}>
                    {getSelect(qboField, index + 1)}
                </Col>

            </Row>
        );
    });

    return (
        <>
            {currentMappings}
        </>
    );
}
