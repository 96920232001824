import { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

export default function useSortable(originalItems, updateItem, orderKey = 'display_order') {
    const dispatch = useDispatch();
    const [bulkUpdating, setBulkUpdating] = useState(false);
    const [sort, setSort] = useState({
        items: originalItems || [],
        dragItem: null,
        hoverIndex: -1,
    });

    useEffect(() => {
        if (!bulkUpdating) {
            setSort((sort) => ({ ...sort, items: originalItems }));
        }
    }, [bulkUpdating, originalItems]);

    const onMove = useCallback((dragIndex, hoverIndex) => {
        const { items } = sort;
        const dragItem = items[dragIndex];
        const item_copy = items.slice();
        item_copy.splice(dragIndex, 1);
        item_copy.splice(hoverIndex, 0, dragItem);
        setSort({
            hoverIndex,
            items: item_copy,
            dragItem: dragItem
        });
    }, [sort]);

    const onDrop = useCallback((a) => {
        const sortCall = async () => {
            setBulkUpdating(true);
            let order = 0;
            for (const i of sort.items) {
                console.log('update order', order, i[orderKey]);
                if (order != i[orderKey]) {
                    await dispatch(updateItem({ ...i, [orderKey]: order }));
                }
                order++;
            }
            setBulkUpdating(false);
        };
        sortCall();
        setSort((sort) => ({ ...sort, hoverIndex: -1, dragItem: null }));
    }, [dispatch, orderKey, sort.items, updateItem]);

    return [
        sort.items,
        onMove,
        onDrop,
    ];
};
