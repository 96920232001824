import React from 'react';
import { Row, Col, Button, LabeledRadioInButton, Loading } from '@commonsku/styles';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import ErrorBoundary from '../../components/ErrorBoundary';
import AdminQuickbooks from './AdminQuickbooks';
import CompanyCustomWebhook from './form/CompanyCustomWebhook';
import AdminXero from './AdminXero';

const customWebhookTenants = [
    'a605e0c1-0c11-42dc-9117-5ea9c78e2d7a', 'dd73ff93-bef6-438d-9ffe-aa85e5f63e70',
    '60d613ea-c3db-4265-9a46-442553c8619a', 'b235f5ca-336a-408a-9586-899338f5f445',
    '6ce967e7-8337-486e-a843-d01831d48ad1', 'bc4a8804-ee0d-4d54-b547-4f94a6392e26',
    '9b1c5539-9464-420e-877d-af3ca0f2301c', 'd825cb61-6b20-4f40-8923-fb65d61a90c5',
    '911c5ac4-8295-4286-b1e4-794ae58a6e53', '1733b63c-6831-4ec9-9938-fea329349634',
    'c5dcb685-35c6-4bd4-adbf-761ebe982954', '6a28120a-73c1-40f3-84fe-e4055b72e130',
    '8be21a7d-ded8-49e7-959f-76fc8124f739',
];

export default function AccountingIntegration() {
    const [{ data }, loading, save, saveExtra, identity] = useCompanyGeneralData();
    const accountingSoftware = data?.companyData?.accounting_software;

    const handleChangeExtraData = (field, value) => {
        saveExtra({
            [field]: value
        });
    };

    if (loading !== 'idle') return <Loading />;

    let canUseCustomWebhook = customWebhookTenants.indexOf(identity.company_id) !== -1;

    return (
        <ErrorBoundary>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                <LabeledRadioInButton flexGrow label="QuickBooks" checked={accountingSoftware === 'QBO'} onChange={(e) => { handleChangeExtraData('accounting_software', 'QBO'); }} />
                <LabeledRadioInButton flexGrow label="Xero" checked={accountingSoftware === 'XERO'} onChange={(e) => { handleChangeExtraData('accounting_software', 'XERO'); }} />
                {canUseCustomWebhook ? <LabeledRadioInButton flexGrow label="Custom Webhook" checked={accountingSoftware === 'WEBHOOK'} onChange={(e) => { handleChangeExtraData('accounting_software', 'WEBHOOK'); }} /> : null}
                <LabeledRadioInButton flexGrow label="XML Export" checked={accountingSoftware === 'XML'} onChange={(e) => { handleChangeExtraData('accounting_software', 'XML'); }} />
                <LabeledRadioInButton flexGrow label="CSV Export" checked={!accountingSoftware} onChange={(e) => { handleChangeExtraData('accounting_software', ''); }} />
            </div>

            <div style={{ padding: '1em 0' }}>
                {accountingSoftware === 'XERO'
                    ? <AdminXero />
                    : accountingSoftware === 'QBO'
                        ? <AdminQuickbooks />
                        : accountingSoftware === 'WEBHOOK'
                            ? <CompanyCustomWebhook save={saveExtra} data={data?.companyData} />
                            : accountingSoftware === 'XML'
                                ? null
                                : null}
            </div>
        </ErrorBoundary>
    );
}
