import React from 'react';

import MarketingSoftwareApp from '../../containers/MarketingSoftwareAppNew';
import AdminTabbedInPopup from './AdminTabbedInPopup';
import AccountingIntegration from './AccountingIntegration';
import CreditCardIntegration from './CreditCardIntegration';
import AdminSage from './AdminSage';
import AdminAvalara from '../avalara/AdminAvalara';
import AdminPromodata from './AdminPromodata';
import { useHasCapabilities } from '../../hooks';
import AdminAvalaraPaywall from '../avalara/AdminAvalaraPaywall';
import MarketingSoftwareAppPaywall from '../../containers/MarketingSoftwareAppPaywall';

const AdminTabbedIntegration = () => {
    const hasMarketingIntegrations = useHasCapabilities(['HAS-MARKETING-INTEGRATIONS']);

    const moduleMap = {
        'marketing': {
            'component': hasMarketingIntegrations ? MarketingSoftwareApp : MarketingSoftwareAppPaywall,
            'title': 'Marketing',
            'show': true,
        },
        'creditcard': {
            'component': CreditCardIntegration,
            'title': 'Payments',
            'show': true,
        },
        'accounting': {
            'component': AccountingIntegration,
            'title': 'Accounting',
            'show': true,
        },
        'sage': {
            'component': AdminSage,
            'title': 'SAGE',
            'show': true,
        },
        'avalara': {
            'component': useHasCapabilities(['HAS-AVALARA']) ? AdminAvalara : AdminAvalaraPaywall,
            'title': 'Avalara',
            'show': true,
        },
        'promodata' : {
            'component' : AdminPromodata,
            'title' : 'Promo Data',
            'show' : true,
        }
    };

    return <AdminTabbedInPopup moduleMap={moduleMap} defaultModule={hasMarketingIntegrations ? 'marketing' : 'creditcard'} />;
};

export default AdminTabbedIntegration;
