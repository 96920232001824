import React from 'react';

const MarketingSoftwareAppPaywall = () => {

    return <>
        Upgrade to Advanced to unlock the Marketing Automation features

        Free up valuable time for your team with marketing and automation integrations. Sync your accounts to elevate your marketing strategy and automate your processes to free up your workload.
    </>;
};

export default MarketingSoftwareAppPaywall;
