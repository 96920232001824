import React from 'react';
import { LightIndicator, PercentIcon } from '@commonsku/styles';
import useCompanyCommisionRate from './hooks/useCompanyCommisionRate';
export default function AdminCommissionSummary({ title, paywalled }) {
    const [rates] = useCompanyCommisionRate();
    return (
        <div style={{ display: 'flex' }}>
            <div><PercentIcon size='large' mr={16} color='#DAE9EE' /></div>
            <div>
                <h5>{title}</h5>
                { !paywalled ?
                    <LightIndicator name={`${rates?.length} rates defined`} on={rates?.length > 0 ? true : false} />
                :
                    <>Upgrade to the Advanced tier to gain access to the Commissions Management feature.
                    Learn More</>
                }
            </div>
        </div>
    );
}
