import { useEffect, useCallback } from 'react';
import { getData, updateData, getExtraData, updateExtraData } from '../../../redux/companyGeneralData';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { oauth } from '../../../utils';

export default function useCompanyGeneralData() {
    const dispatch = useDispatch();
    const identity = useSelector(state => state.identity);
    const data = useSelector(state => state.companyGeneralData);
    const loading = data.loading;
    const type = identity.company_type === 'SUPPLIER' ? 'supplier' : 'tenant';

    const reloadData = useCallback(async () => {
        await dispatch(getData(identity.company_id, type));
        await dispatch(getExtraData(identity.company_id));
    }, [dispatch, identity.company_id, type]);

    const disconnectQuickbook = useCallback(async () => {
        const res = await oauth('POST', 'qbo', { 'action': 'disconnect' });
        await reloadData();

    }, [reloadData]);

    const disconnectStripe = useCallback(async () => {
        const res = await oauth('POST', 'stripe', { 'action': 'disconnect' });
        await reloadData();

    }, [reloadData]);

    useEffect(() => {
        if (!data.data.active) {
            reloadData();
        }
    }, [data.data.active, reloadData]);

    const save = useCallback((data, showToast = true) => {
        const updateCall = async () => {
            const result = await dispatch(updateData(identity.company_id, type, data));
            if (result === true) {
                if (showToast) toast.success("Saved successfully");
            } else {
                toast.error(result);
            }
        };

        return updateCall();
    }, [dispatch, identity.company_id, type]);

    const saveExtra = useCallback((data, showToast = false) => {
        const updateCall = async () => {
            const result = await dispatch(updateExtraData(identity.company_id, data));
            if (result === true) {
                if (showToast) toast.success("Saved successfully");
            } else {
                toast.error(result);
            }
        };

        updateCall();
    }, [dispatch, identity.company_id]);

    return [
        data,
        loading,
        save,
        saveExtra,
        identity,
        reloadData,
        disconnectQuickbook,
        disconnectStripe,
    ];
};
